import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Wrapper } from '../Shared/Wrapper';
import SeoHeader from '../Shared/SeoHeader';
import { PageTitle } from '../Shared/PageTitle';
import { Loader } from '../Shared/Loaders/Loader';
import { Profile } from '../Profile';
import { useGetUserLazyQuery } from '../../graphql/__types';
import { useParams } from '@reach/router';
import { ExclamationIcon } from '@heroicons/react/solid';

export function UserDetail(_: RouteComponentProps) {
  const { id } = useParams();
  const [isInitialLoaded, setInitialLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [getUser, { loading, data, error }] = useGetUserLazyQuery();

  const user = useMemo(() => {
    if (!!data) return data.getUser;
    return null;
  }, [data]);

  useEffect(() => {
    if (!isInitialLoaded) {
      getUser({
        variables: {
          getUserId: id,
        },
      });
    }
  }, [isInitialLoaded]);

  useEffect(() => {
    if (((data && !error) || error) && !loading) {
      setInitialLoaded(true);
    }
  }, [error, loading, data]);

  useEffect(() => {
    if (!!data && data.getUser === null) {
      setIsError(true);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setInitialLoaded(false);
    };
  }, []);

  return (
    <Wrapper>
      <SeoHeader title={!!user && user !== null ? user.name : ''} />

      <section className="flex flex-col w-full">
        <section className="w-full md:w-10/12 md:mx-auto pt-5">
          <PageTitle value="User Details" />

          <section className="shadow-lg p-5 rounded-lg my-5">
            <section className="mt-5">
              {!isInitialLoaded ? (
                <section className="flex justify-center items-center">
                  <Loader />
                </section>
              ) : !isError ? (
                <Profile />
              ) : (
                <section className="flex flex-col items-center justify-center">
                  <ExclamationIcon className="w-28 h-28 text-primary-1" />
                  <h1 className="text-xl font-bold">
                    The operation could not be completed
                  </h1>
                  <p>An unknown error occured</p>
                </section>
              )}
            </section>
          </section>
        </section>
      </section>
    </Wrapper>
  );
}
