import React, { useEffect } from 'react';
import { useGetUserQuery, UserRoleEnum } from '../../graphql/__types';
import { ProfileCard } from './ProfileCard';
import { ProfileForm } from './ProfileForm';
import { useParams, useLocation } from '@reach/router';
import { ExclamationIcon } from '@heroicons/react/solid';
import { ProfileRole } from './ProfileRole';
import { getUser } from '../../services/auth';
import { SectionTitle } from '../Shared/PageTitle';

export function Profile() {
  const { pathname } = useLocation();
  const { id } = useParams();

  const { data } = useGetUserQuery({
    fetchPolicy: 'cache-only',
    variables: {
      getUserId: id,
    },
  });

  function showControls() {
    const pathArray = pathname.split('/');
    return pathArray[1] === 'users';
  }

  return (
    <section className="md:flex md:flex-row md:divide-x">
      <section className="md:w-4/12 pr-5">
        <ProfileCard />
      </section>

      <section className="md:w-8/12 md:px-5 py-2">
        {!!data && !!data.getUser && !data.getUser.isCheckpoint ? (
          <section className="divide-y">
            <>
              <SectionTitle value="Update Details" />
              <ProfileForm />
            </>

            {getUser().role === UserRoleEnum.Administrator && showControls() ? (
              <>
                <ProfileRole />
              </>
            ) : null}
          </section>
        ) : (
          <section className="flex flex-col items-center justify-center">
            <ExclamationIcon className="w-28 h-28 text-primary-1" />
            <h1 className="text-xl font-bold">
              The operation could not be completed
            </h1>
            <p>User needs to join a atleast one (1) webinar</p>
          </section>
        )}
      </section>
    </section>
  );
}
