import React, { useEffect, useState } from 'react';
import {
  OptionEnum,
  UpdateUserInput,
  useGetUserQuery,
  UserIndustryEnum,
  UserRoleEnum,
  UserSexEnum,
  useUpdateUserMutation,
} from '../../graphql/__types';
import { useParams } from '@reach/router';
import { Input } from '../Shared/Form/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { FormGroup } from '../Shared/Form/FormGroup';
import { useAddress } from '../../hooks/useAddress';
import { ErrorMessage } from '../Shared/ErrorMessage';
import { Button } from '../Shared/Button';
import { getUser } from '../../services/auth';
import { toast } from 'react-toastify';

export function ProfileForm() {
  const [isEmployee, setIsEmployee] = useState<boolean>(false);
  const { id } = useParams();

  const { data } = useGetUserQuery({
    fetchPolicy: 'cache-only',
    variables: {
      getUserId: id,
    },
  });

  const [updateUser, { loading, data: mutationData }] = useUpdateUserMutation();
  const methods = useForm<UpdateUserInput>();
  const [isError, setIsError] = useState<boolean>(false);

  const {
    regions,
    handleChangeRegion,
    provinces,
    handleChangeProvince,
    cities,
    handleChangeCity,
    barangays,
  } = useAddress();

  const regionCode = methods.watch('region');
  const provinceCode = methods.watch('province');
  const citymunCode = methods.watch('city');

  useEffect(() => {
    if (!!data && !!data.getUser) {
      const email = data.getUser.email;

      if (!!email && email !== null) {
        const isEmailValid = email.split('@')[1] === 'region1.dost.gov.ph';
        if (isEmailValid) setIsEmployee(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!!regionCode) {
      handleChangeRegion(regionCode);
      methods.setValue('province', '', { shouldDirty: false });
      methods.setValue('city', '', { shouldDirty: false });
      methods.setValue('barangay', '', { shouldDirty: false });
    }
  }, [regionCode]);

  useEffect(() => {
    if (!!provinceCode) {
      handleChangeProvince(provinceCode);
      methods.setValue('city', '', { shouldDirty: false });
      methods.setValue('barangay', '', { shouldDirty: false });
    }
  }, [provinceCode]);

  useEffect(() => {
    if (!!citymunCode) {
      handleChangeCity(citymunCode);
      methods.setValue('barangay', '', { shouldDirty: false });
    }
  }, [citymunCode]);

  useEffect(() => {
    if (!!data && !!data.getUser && data.getUser !== null) {
      methods.setValue('name', data.getUser.certificateName);
      methods.setValue('sex', data.getUser.sex);
      methods.setValue('age', data.getUser.age);
      methods.setValue('organization', data.getUser.organization);
      methods.setValue('industry', data.getUser.industry);
      methods.setValue('jobTitle', data.getUser.jobTitle);
      methods.setValue('isIndigenous', data.getUser.isIndigenous);
      methods.setValue(
        'isPersonWithDisability',
        data.getUser.isPersonWithDisability,
      );
    }

    setTimeout(() => {
      if (!!data && !!data.getUser && data.getUser !== null) {
        methods.setValue('region', data.getUser.regionCode);
      }
    }, 100);

    setTimeout(() => {
      if (!!data && !!data.getUser && data.getUser !== null) {
        methods.setValue('province', data.getUser.provinceCode);
      }
    }, 200);

    setTimeout(() => {
      if (!!data && !!data.getUser && data.getUser !== null) {
        methods.setValue('city', data.getUser.cityCode);
      }
    }, 300);

    setTimeout(() => {
      if (!!data && !!data.getUser && data.getUser !== null) {
        methods.setValue('barangay', data.getUser.barangayCode);
      }
    }, 400);
  }, [data]);

  useEffect(() => {
    if (!loading && !!mutationData && mutationData.updateUser === null) {
      setIsError(true);
    }
  }, [loading, mutationData]);

  async function onSubmit(data: UpdateUserInput) {
    await updateUser({
      variables: {
        updateUserInput: {
          ...data,
          id,
        },
      },
    });

    toast.success('Profile has been updated');
  }

  return (
    <FormProvider {...methods}>
      <form className="py-5" onSubmit={methods.handleSubmit(onSubmit)}>
        {getUser().role === UserRoleEnum.Administrator ? (
          <Input
            label="Full Name"
            name="name"
            as="input"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Region is required',
              },
            }}
          />
        ) : null}

        <FormGroup>
          <Input
            label="Sex"
            name="sex"
            as="select"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Sex is required',
              },
            }}
          >
            <option value="" disabled selected>
              -Select sex-
            </option>
            <option value={UserSexEnum.Female}>{UserSexEnum.Female}</option>
            <option value={UserSexEnum.Male}>{UserSexEnum.Male}</option>
            <option value={UserSexEnum.Unknown}>Prefer Not to Say</option>
          </Input>

          <Input
            label="Age"
            name="age"
            as="input"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Age is required',
              },
            }}
          />
        </FormGroup>

        <FormGroup>
          <Input
            label="Region"
            name="region"
            as="select"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Region is required',
              },
            }}
          >
            <option value="" disabled selected>
              -Select region-
            </option>
            {regions.map((region) => (
              <option key={region.id} value={region.regCode}>
                {region.regDesc}
              </option>
            ))}
          </Input>

          <Input
            label="Province"
            name="province"
            as="select"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Province is required',
              },
            }}
          >
            <option value="" disabled selected>
              -Select province-
            </option>
            {provinces.map((province) => (
              <option key={province.id} value={province.provCode}>
                {province.provDesc}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup>
          <Input
            label="City/Municipality"
            name="city"
            as="select"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'City is required',
              },
            }}
          >
            <option value="" disabled selected>
              -Select city/municipality-
            </option>
            {cities.map((city) => (
              <option key={city.id} value={city.citymunCode}>
                {city.citymunDesc}
              </option>
            ))}
          </Input>
          <Input
            label="Barangay"
            name="barangay"
            as="select"
            placeholder="Barangay"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Barangay is required',
              },
            }}
          >
            <option value="" disabled selected>
              -Select barangay-
            </option>
            {barangays.map((barangay) => (
              <option key={barangay.id} value={barangay.brgyCode}>
                {barangay.brgyDesc.toUpperCase()}
              </option>
            ))}
          </Input>
        </FormGroup>

        <Input
          label="Organization/Company/Agency"
          name="organization"
          as="input"
          disabled={loading}
          validation={{
            required: {
              value: true,
              message: 'Organization is required',
            },
          }}
          helperText="Do not abbreviate"
        />

        <FormGroup>
          <Input
            label="Industry"
            name="industry"
            as="select"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Industry is required',
              },
            }}
          >
            <option value="" selected disabled>
              -Select industry-
            </option>
            {isEmployee ? (
              <option value={UserIndustryEnum.Employee}>DOST 1</option>
            ) : (
              <>
                <option value={UserIndustryEnum.Icieerd}>
                  ICIEERD Members
                </option>
                <option value={UserIndustryEnum.Cest}>CEST</option>
                <option value={UserIndustryEnum.Academe}>Academe</option>
                <option value={UserIndustryEnum.Msme}>MSMEs</option>
                <option value={UserIndustryEnum.Media}>Media</option>
                <option value={UserIndustryEnum.Nga}>
                  National Government Agency (NGA)
                </option>
                <option value={UserIndustryEnum.Researcher}>
                  Researcher/Inventor
                </option>
                <option value={UserIndustryEnum.Student}>Students</option>
                <option value={UserIndustryEnum.Food}>
                  Food Establishment Owner
                </option>
                <option value={UserIndustryEnum.Setup}>
                  SETUP Beneficiary
                </option>
                <option value={UserIndustryEnum.Lgu}>
                  Local Government Unit (LGU)
                </option>
                <option value={UserIndustryEnum.Other}>Others</option>
              </>
            )}
          </Input>

          <Input
            label="Job Title"
            name="jobTitle"
            as="input"
            placeholder="Job Title"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Job Title is required',
              },
            }}
            helperText="Do not abbreviate"
          />
        </FormGroup>

        <FormGroup>
          <Input
            label="Indigenous People"
            name="isIndigenous"
            as="select"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Field is required',
              },
            }}
          >
            <option value="" disabled selected>
              -Select-
            </option>
            <option value={OptionEnum.Yes}>Yes</option>
            <option value={OptionEnum.No}>No</option>
          </Input>
          <Input
            label="PWD"
            name="isPersonWithDisability"
            as="select"
            disabled={loading}
            validation={{
              required: {
                value: true,
                message: 'Field is required',
              },
            }}
          >
            <option value="" disabled selected>
              -Select-
            </option>
            <option value={OptionEnum.Yes}>Yes</option>
            <option value={OptionEnum.No}>No</option>
          </Input>
        </FormGroup>

        {isError && <ErrorMessage message="Internal Server Error" />}

        <Button type="submit" primary size="sm" loading={loading}>
          <span>Save</span>
        </Button>
      </form>
    </FormProvider>
  );
}
