import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  UpdateUserRoleInput,
  useGetUserLazyQuery,
  UserRoleEnum,
  useUpdateUserRoleMutation,
} from '../../graphql/__types';
import { Input } from '../Shared/Form/Input';
import { SectionTitle } from '../Shared/PageTitle';
import { useParams } from '@reach/router';
import { Button } from '../Shared/Button';

export function ProfileRole() {
  const { id } = useParams();
  const methods = useForm<UpdateUserRoleInput>();
  const [updateRole, { loading }] = useUpdateUserRoleMutation({
    update(cache, { data: cacheData }) {
      if (!!cacheData && !!cacheData.updateUserRole) {
        cache.modify({
          id: cache.identify({
            __typename: 'User',
            id,
          }),
          fields: {
            role() {
              return methods.getValues().role;
            },
          },
        });
      }
    },
  });

  const [getUser, { data }] = useGetUserLazyQuery({
    variables: {
      getUserId: id,
    },
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (!!data && !!data.getUser && !!data.getUser.role) {
      methods.setValue('role', data.getUser.role);
    }
  }, [data]);

  useEffect(() => {
    if (!!id) getUser();
  }, [id]);

  async function onSubmit(data: UpdateUserRoleInput) {
    await updateRole({
      variables: {
        updateUserRoleInput: {
          id,
          role: data.role,
        },
      },
    });
  }

  return (
    <section className="py-5">
      <SectionTitle value="Role" />

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="w-full md:w-4/12 flex flex-row items-center space-x-2"
        >
          <Input name="role" as="select" disabled={loading}>
            <option value={UserRoleEnum.Administrator}>
              {UserRoleEnum.Administrator}
            </option>
            <option value={UserRoleEnum.Organizer}>
              {UserRoleEnum.Organizer}
            </option>
            <option value={UserRoleEnum.User}>{UserRoleEnum.User}</option>
          </Input>

          <Button type="submit" primary size="sm" loading={loading}>
            <span>Save</span>
          </Button>
        </form>
      </FormProvider>
    </section>
  );
}
