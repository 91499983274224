import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { PublicLayout } from '../components/Shared/PublicLayout';
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import SeoHeader from '../components/Shared/SeoHeader';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';
import { UserList } from '../components/User/UserList';
import { UserDetail } from '../components/User/UserDetail';

export default function UsersPage() {
  return (
    <PublicLayout>
      <Router basepath="users">
        <PrivateRoute component={UserList} path="/" roles={['Administrator']} />
        <PrivateRoute
          component={UserDetail}
          path="/:id"
          roles={['Administrator']}
        />
        <NotFoundRedirect path="*" />
      </Router>
    </PublicLayout>
  );
}
