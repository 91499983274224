import { ArrowRightIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { User } from '../../graphql/__types';

type Props = {
  data: User;
  hoverable?: boolean;
};

export function UserItem({ data, hoverable }: Props) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <section
      className={`rounded-lg ${
        hoverable ? `cursor-pointer hover:bg-gray-100` : ''
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <section className="flex flex-row justify-between items-center px-5 py-3">
        <section className="flex items-center space-x-2">
          <img
            src={`${`https://avatars.dicebear.com/api/identicon/${data.id}.svg`}`}
            className="rounded-full border border-gray-300 w-6 h-6"
          />

          <section className="flex flex-col">
            <h1>{data.name}</h1>
            <p className="text-sm text-gray-400">{data.email}</p>
          </section>
        </section>

        <section className={isHovered ? 'block' : 'hidden'}>
          <ArrowRightIcon className="w-4 h-4" />
        </section>
      </section>
    </section>
  );
}
