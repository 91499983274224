import React from 'react';
import { useGetUserQuery } from '../../graphql/__types';
import { useParams } from '@reach/router';
import { LocationMarkerIcon, MailIcon } from '@heroicons/react/outline';

export function ProfileCard() {
  const { id } = useParams();

  const { data } = useGetUserQuery({
    fetchPolicy: 'cache-only',
    variables: {
      getUserId: id,
    },
  });

  return !!data && data.getUser ? (
    <section className="flex flex-col">
      <img
        src={`${`https://avatars.dicebear.com/api/identicon/${id}.svg`}`}
        className="rounded-full border border-gray-300 w-28 h-28"
      />

      <section className="mt-5">
        <h1 className="uppercase font-semibold text-md mb-1">
          {data.getUser.certificateName}
        </h1>
        <span className="rounded-lg p-2 bg-gray-400 text-white text-xs uppercase">
          {data.getUser.role}
        </span>
      </section>

      <section className="my-10">
        <section className="flex items-center text-sm space-x-2">
          <MailIcon className="w-4 h-4" />
          <span>{data.getUser.email}</span>
        </section>

        <section className="flex items-center text-sm space-x-2">
          <LocationMarkerIcon className="w-4 h-4" />
          <span>
            {data.getUser.city}, {data.getUser.province}
          </span>
        </section>
      </section>

      <section className="text-xs">
        Note: Above name will be used for certificates
      </section>
    </section>
  ) : null;
}
