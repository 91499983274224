import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Wrapper } from '../../Shared/Wrapper';
import SeoHeader from '../../Shared/SeoHeader';
import { PageTitle } from '../../Shared/PageTitle';
import { Loader } from '../../Shared/Loaders/Loader';
import { UserItem } from '../UserItem';
import { useGetUsersLazyQuery, User } from '../../../graphql/__types';
import { isBrowser } from '../../../services/auth';
import { navigate } from 'gatsby-link';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import { debounce } from 'lodash';

const PAGE_SIZE = 10;

export function UserList(_: RouteComponentProps) {
  const searchRef = useRef(null);

  const [search, setSearch] = useState<string>('');

  const {
    isInitialLoaded,
    isLoading,
    data,
    loadData,
    resetData,
    totalRecords,
    lastItemRef,
    lastItemInView,
  } = useInfiniteScroll<User>();

  const [getUsers] = useGetUsersLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => loadData(data, 'getUsers', search),
  });

  useEffect(() => {
    const searchField = searchRef.current as any;

    if (searchField !== null) searchField.focus();
  }, [searchRef]);

  useEffect(() => {
    if (!isInitialLoaded) fetchData();
  }, [isInitialLoaded]);

  useEffect(() => {
    if (lastItemInView && search === '') fetchData();
  }, [lastItemInView]);

  function fetchData(searchValue?: string) {
    getUsers({
      variables: {
        getUsersQuery: {
          offset: data.length,
          pageSize: PAGE_SIZE,
          search: searchValue,
        },
      },
    });
  }

  useEffect(() => {
    return () => {
      resetData();
    };
  }, []);

  function userClickHandler(id: string) {
    if (isBrowser()) navigate(`/users/${id}`);
  }

  const searchHandler = (e: any) => {
    const raw = e.target.value as string;
    const value = raw.toLowerCase().trim();

    if (value.length > 0) {
      setSearch(value);
      return fetchData(value);
    }

    setSearch('');
    fetchData();
  };

  const debouncedSearch = useCallback(debounce(searchHandler, 500), []);

  return (
    <Wrapper>
      <SeoHeader title="Users" />

      <section className="flex flex-col w-full">
        <section className="w-full md:w-6/12 md:mx-auto pt-5">
          <PageTitle value="Users" />

          <section className="shadow-lg p-5 rounded-lg my-5">
            <section className="flex flex-row justify-between items-center mb-5">
              <section className="uppercase text-sm">
                <p className="text-right">
                  {search === '' ? 'Total Registered' : 'Search Result'}:{' '}
                  {totalRecords}
                </p>
              </section>

              <input
                className="border bg-white border-gray-300 outline-none focus:border-primary-2 rounded-md px-3 py-2"
                placeholder="Search"
                onChange={debouncedSearch}
                ref={searchRef}
              />
            </section>

            {!isInitialLoaded ? (
              <section className="flex justify-center items-center">
                <Loader />
              </section>
            ) : totalRecords > 0 ? (
              data.map((user, index) => {
                if (user == null) return;

                if (data.length === index + 1) {
                  return (
                    <div
                      key={user.id}
                      onClick={() => userClickHandler(user.id)}
                      ref={lastItemRef}
                    >
                      <UserItem data={user} hoverable />
                    </div>
                  );
                }

                return (
                  <div key={user.id} onClick={() => userClickHandler(user.id)}>
                    <UserItem data={user} hoverable />
                  </div>
                );
              })
            ) : search === '' ? (
              <h1 className="text-center uppercase font-medium">
                No users yet
              </h1>
            ) : null}

            {search !== '' && data.length === 0 ? (
              <h1 className="text-center uppercase font-medium">
                User not found
              </h1>
            ) : null}

            {isLoading ? (
              <section className="flex justify-center mt-5">
                <Loader />
              </section>
            ) : null}

            {data.length < totalRecords && search.trim().length === 0 ? (
              <section className="text-center mt-5 w-full md:w-3/12 mx-auto text-sm">
                <section
                  className="uppercase bg-gray-50 hover:bg-gray-100 text-gray-500 p-2  rounded-lg hover:opacity-80 cursor-pointer"
                  onClick={() => fetchData()}
                >
                  Load More
                </section>
              </section>
            ) : null}
          </section>
        </section>
      </section>
    </Wrapper>
  );
}
